import ForgottenPasswordModal from '@/components/modal/ForgottenPasswordModal.vue'
import LoginModal from '@/components/modal/LoginModal.vue'
import RewardModal from '@/components/modal/RewardModal.vue'
import { useCustomModal } from '@/modalHelper'
import { useModalSlot } from 'vue-final-modal'
import type { RewardModalProps } from '~/types'

const onOpenRewardModal = async (rewardProps: RewardModalProps) => {
  loginModal.patchOptions(getRewardModalConfig(rewardProps))
}
const getRewardModalConfig = (rewardProps: RewardModalProps) => ({
  attrs: {
    modalId: 'reward',
    title: rewardProps.title,
    clickToClose: false,
  },
  slots: {
    default: useModalSlot({
      component: RewardModal,
      attrs: {
        data: rewardProps,
      },
    }),
  },
})

const onOpenForgottenPassword = async () => {
  loginModal.patchOptions(getForgotPasswordModalConfig())
}

const resetLoginModal = () => {
  loginModal.patchOptions(getLoginModalConfig())
}

const getLoginModalConfig = () => ({
  attrs: {
    modalId: 'login',
    title: 'Connexion',
    clickToClose: false,
    onClosed: resetLoginModal,
  },
  slots: {
    default: useModalSlot({
      component: LoginModal,
      attrs: {
        onOpenForgottenPassword,
        onOpenRewardModal,
      },
    }),
  },
})

export const loginModal = useCustomModal(getLoginModalConfig())

const getForgotPasswordModalConfig = () => ({
  attrs: {
    modalId: 'password',
    title: 'Mot de passe oublié ?',
    clickToClose: false,
  },
  slots: {
    default: useModalSlot({
      component: ForgottenPasswordModal,
      attrs: {
        onOpenLoginModal: resetLoginModal,
      },
    }),
  },
})
